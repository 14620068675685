import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import { NotificationService } from "src/app/services/notification.service";
import {
  filter,
  map,
  tap,
  shareReplay,
  switchMap,
  debounceTime,
  take,
  catchError,
} from "rxjs/operators";
import { LayoutService } from "src/app/services/layout.service";
import { WelcomeExperienceService } from "src/app/services/first-access.service";
import { UserService } from "src/app/services/user.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { ConfigurationService } from "src/app/services/configuration.service";
import { of, merge, Observable } from "rxjs";
import {
  UserCategory,
  UserNotification,
  EntityType,
  ContactType,
  Contact,
  Challenge,
  ImpactingKnowledgeType,
  User,
  GuestBadge,
  UserInfo,
  ExternalUser,
  NetworkType,
} from "src/app/shared/entities";
import { GamificationService } from "src/app/services/gamification.service";
import * as _ from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  SearchService,
  KMSSearchResults,
} from "src/app/services/search.service";
import { WebinarsService } from "src/app/services/webinars.service";
import { Utilities } from "src/app/shared/utils";
import * as dayjs from "dayjs";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { BottomSheetComponent } from "./bottom-sheet/bottom-sheet.component";
import { Network } from "vis";
import { ValidationService } from "src/app/services/validation.service";

@Component({
  selector: "kms-menu",
  templateUrl: "./kms-menu.component.html",
  styleUrls: ["./kms-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KmsMenuComponent implements OnInit, AfterViewInit {
  menu: MenuItem[] = [];
  myScore: any;
  activeMenuItem: MenuItem;
  askNotificationConfirm = false;
  public notificationsNumber = 0;
  private idList: string[] = [];
  isMobile = false;
  allNotifications: UserNotification[];
  isLoading = false;
  modalRef: BsModalRef;
  tooltip = "";
  notificationEnabled = true;
  currentMonth = new Date().getMonth() + 1;
  kmsTeam: Contact[];
  kmsHelp: Contact[];
  kmsWebinar: Contact[];
  liveSoon: any[] = null;
  myBookings: any[] = null;
  loading = true;
  expiringSoon: Observable<Challenge[]>;
  expiringSoonError = false;
  expired: Observable<Challenge[]>;
  expiredError = false;
  mostVoted: Observable<Challenge[]>;
  mostVotedError = false;
  loadingIndicator = false;
  isLeleEnabled = false;
  opened;
  closed;
  popular;
  filter = {
    select: ["id", "name", "logoUrl", "ideasCount", "challengeEndDate"],
    filter: `(typeId eq ${ImpactingKnowledgeType.Challenge})`,
    orderBy: ["ideasCount desc"],
    top: 5,
  };
  expiringSoonFilter = {
    select: ["id", "name", "logoUrl", "ideasCount", "challengeEndDate"],
    filter: `(typeId eq ${ImpactingKnowledgeType.Challenge} and status eq 'Open')`,
    orderBy: ["challengeEndDate asc"],
  };

  expiredFilter = {
    select: ["id", "name", "logoUrl", "ideasCount", "challengeEndDate"],
    filter: `(typeId eq ${ImpactingKnowledgeType.Challenge} and status eq 'Closed')`,
    orderBy: ["challengeEndDate desc"],
    top: 5,
  };

  isEnabled: boolean = true;
  externalUser: ExternalUser;
  externalUserHasCops: boolean = true;
  externalUserHasBus: boolean = true;
  externalUserHasSpecialtag: boolean = true;
  externalUserHasPrivateNetwork: boolean = true;
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    if (this.modalSvc.getModalsCount()) {
      this.modalSvc._hideModal(this.modalSvc.getModalsCount());
    }
  }

  constructor(
    private readonly notificationSvc: NotificationService,
    private readonly welcomeSvc: WelcomeExperienceService,
    public readonly layoutSvc: LayoutService,
    public readonly userSvc: UserService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
    public readonly configSvc: ConfigurationService,
    public readonly gamSvc: GamificationService,
    private readonly modalSvc: BsModalService,
    private readonly searchSvc: SearchService,
    private readonly webinarSvc: WebinarsService,
    private _bottomSheet: MatBottomSheet,
    private validationService: ValidationService
  ) { }

  get show() {
    return (
      this.userSvc.userProfile &&
      (this.userSvc.userCategory === UserCategory.Employee ||
        this.userSvc.userCategory === UserCategory.External)
    );
  }

  ngOnInit() {

    if (
      this.userSvc.userProfile.isExternalConsultant == true &&
      this.userSvc.isGuest == false
    ) {
      this.userSvc
        .getExternalUserByUPN(this.userSvc.userProfile.upn)
        .pipe(
          tap((externalUser) => {
            this.externalUser = externalUser;
            this.isEnabled = this.externalUser.isLeleEnabled;
          }),
        )
        .subscribe();
    }

    if (this.userSvc.userCategory !== UserCategory.Guest) {
      this.configSvc
        .getContactsBytype(ContactType.KMTeam)
        .subscribe((contacts) => (this.kmsTeam = contacts));
      this.configSvc
        .getContactsBytype(ContactType.KMSHelp)
        .subscribe((contacts) => (this.kmsHelp = contacts));
      this.configSvc
        .getContactsBytype(ContactType.KMWebinar)
        .subscribe((contacts) => (this.kmsWebinar = contacts));
    }

    this.getWebinarsLiveSoon();
    this.getBookingsMyBookings();

    this.getExpiringSoon();
    this.getExpired();
    this.getMostVoted();
    this.updateSearchFilters();

    this.modalSvc.onShow.subscribe(() => {
      window.history.pushState({}, "");
    });
    this.modalSvc.onHide.subscribe(() => {
      setTimeout(() => {
        // window.history.go(-1);
        this.cd.markForCheck();
      }, 100);
    });
    this.notificationSvc.readNot.subscribe(() => {
      this.readNotifications();
    });
    // this.getUnread();
    this.listenToNotificationsChange();
    this.configSvc.isMobile.subscribe((el) => {
      this.isMobile = el;
      this.cd.markForCheck();
    });
    // console.log(this.configSvc.isMobile);
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => {
          return event.urlAfterRedirects;
        })
      )
      .subscribe((url) => {
        this.highlightActivePage(url);
        this.cd.markForCheck();
      });
    this.gamSvc.myScore$.subscribe((score) => {
      this.myScore = score;
      if (this.myScore && this.myScore.medal) {
        this.tooltip = "My Level:" + this.myScore.medal.name;
      }
    });

    this.welcomeSvc.isFirstAccessActivated.subscribe(() => {
      this.buildMenu();
    });

    this.buildMenu();

    this.highlightActivePage(document.location.pathname);

    if (
      this.userSvc.userProfile &&
      this.userSvc.userCategory !== UserCategory.Guest
    ) {
      this.gamSvc.getMyScore();
    }

    if (
      this.userSvc.userProfile &&
      this.userSvc.userCategory === UserCategory.Guest
    ) {
      this.notificationEnabled = false;
    }
  }


  clearTouch() {
    this.validationService.isFormInvalid$.next(false);
  }

  getExternalUserCops(): Network[] {
    return this.externalUser.networks.filter((network) => network.type == 1);
  }

  private async buildMenu() {
    if (
      this.userSvc.userProfile.isExternalConsultant == true &&
      this.userSvc.isGuest == false
    ) {
      this.externalUser = await this.userSvc
        .getExternalUserByUPN(this.userSvc.userProfile.upn)
        .toPromise();

      // type 1 --> COP
      // type 2 --> BU
      this.externalUserHasCops =
        this.externalUser.networks?.filter(
          (network) => network.item2 == NetworkType.CoP
        ).length > 0;
      this.externalUserHasBus =
        this.externalUser.networks?.filter(
          (network) => network.item2 == NetworkType.BusinessUnit
        ).length > 0;
      this.externalUserHasSpecialtag =
        this.externalUser.networks?.filter(
          (network) => network.item2 == NetworkType.SpecialTag
        ).length > 0;
      this.externalUserHasPrivateNetwork =
        this.externalUser.networks?.filter(
          (network) => network.item2 == NetworkType.External
        ).length > 0;
    }

    if (this.userSvc.userCategory === UserCategory.Guest) {
      this.menu = [
        {
          text: "Networks",
          icon: "fa fa-comments",
          link: "/external-network/list",
        }
        // {
        //   text: "Favorites ",
        //   icon: "fa fa-star",
        //   link: "/favorites"
        // }
      ];
    } else if (
      this.userSvc.userCategory === UserCategory.Employee ||
      this.userSvc.userCategory === UserCategory.External
    ) {
      const roles =
        this.userSvc && this.userSvc.userProfile
          ? this.userSvc.userRoles
          : null;
      if (
        roles &&
        (roles.admin ||
          roles.kmTeam ||
          (roles.externalReferentScopes &&
            roles.externalReferentScopes.length > 0) ||
          (roles.externalMemberScopes && roles.externalMemberScopes.length > 0))
      ) {
        // only if user have access to private network
        this.menu = [
          // { text: "Home", icon: "fa fa-home", link: "" },
          {
            text: "Networks",
            icon: "fa fa-comments",
            link: "/network",
            children: [
              {
                text: this.externalUserHasCops ? "CoPs" : "",
                icon: this.externalUserHasCops ? "regular fa fa-users" : "",
                link: this.externalUserHasCops ? "/network/list/cop" : "",
              },
              {
                text: this.externalUserHasBus ? "Business Units" : "",
                icon: this.externalUserHasBus ? "regular fa fa-sitemap" : "",
                link: this.externalUserHasBus ? "/network/list/bu" : "",
              },
              {
                text: this.externalUserHasSpecialtag ? "Special Tags" : "",
                icon: this.externalUserHasSpecialtag
                  ? "regular fa fa-hashtag"
                  : "",
                link: this.externalUserHasSpecialtag
                  ? "/network/list/strategictag"
                  : "",
              },
              {
                text: this.externalUserHasPrivateNetwork
                  ? "Private Networks"
                  : "",
                icon: this.externalUserHasPrivateNetwork
                  ? "regular fas fa-unlock-alt"
                  : "",
                link: this.externalUserHasPrivateNetwork
                  ? "/external-network/list"
                  : "",
              },
            ],
          },
          {
            text: "Knowledge",
            icon: "fa fa-puzzle-piece",
            link: "",
            children: [
              // {
              //   text: "Impacting Knowledges",
              //   icon: "fa fa-diamond",
              //   link: "/ik-archive"
              //   // query: {
              //   //   hideSources: true,
              //   //   source: "impactingknowledges",
              //   //   pageTitle: "Knowledge Nuggets Archive"
              //   // }
              // },
              {
                text: "Knowledge Nuggets",
                icon: "regular fa fa-gem",
                link: "/knowledge-nugget/archive",
              },
              {
                text: "IIM Challenges",
                icon: "regular fa fa-bullhorn",
                link: "",
                children: [
                  {
                    text: "Archive",
                    icon: "regular fa fa-search",
                    link: "/challenge/archive",
                  },
                  {
                    text: "Open",
                    icon: "regular fa fa-unlock-alt",
                    link: "",
                  },
                  {
                    text: "Closed",
                    icon: "regular fa fa-lock",
                    link: "",
                  },
                  {
                    text: "Popular",
                    icon: "regular fa fa-lightbulb",
                    link: "",
                  },
                ],
              },
              {
                text: "Ideas",
                icon: "regular fa fa-lightbulb",
                link: "/idea/archive",
                // query: {
                //   hideSources: true,
                //   source: "impactingknowledges",
                //   pageTitle: "Knowledge Nuggets Archive"
                // }
              },
              // {
              //   text: "Success stories",
              //   icon: "regular fa fa-book",
              //   link: "/success-story/archive"
              // },
              // {
              //   text: "Lesson Learned from LeLe",
              //   icon: "regular fa fa-gavel",
              //   link: `dotlessonslearned`
              // }
            ],
          },
          {
            text: "Success stories",
            icon: "fa fa-book",
            link: "/success-story/archive",
          },
          {
            text: "Webinars ",
            icon: "fas fa-video",
            link: "",
            children: [
              {
                text: "Live soon",
                icon: "fa fa-broadcast-tower",
                link: "",
              },
              {
                text: "My Booking Pending",
                icon: "fa fa-folder",
                link: "",
              },
              {
                text: "Calendar & Booking",
                icon: "fas fa-calendar-alt",
                link: "/webinars/calendar",
              },
              {
                text: "Search",
                icon: "fas fa-video",
                link: "/webinars/list",
              },
            ],
          },
          {
            text: "Lesson Learned",
            icon: "lele-logo",
            link: "/lesson-learned/list",
          },
          {
            text: "Standards",
            icon: "Standards-logo",
            link: "",
            children: [
              {
                text: "Company Standards",
                icon: "Standards-logo",
                link: "https://rms.eni.com/RMS/Search/Display"
              }
            ]
          },
          // {
          //   text: "How to ",
          //   icon: "fa fa-question-circle",
          //   link: "/how-to"
          // },
          // {
          //   text: "Favorites ",
          //   icon: "fa fa-star",
          //   link: "/favorites"
          // }
          {
            text: "Help ",
            icon: "fa fa-question-circle",
            link: "",
            children: [
              {
                text: "Contacts",
                icon: "fa fa-address-book",
                link: "",
                children: [
                  {
                    text: "E-kms HelpDesk",
                    icon: "",
                    link: "",
                    description: "(for technical issues and access)"
                  },
                  {
                    text: "Eni Webinar",
                    icon: "",
                    link: "",
                    description: "(for problems with webinars, confirmation and test)"
                  },
                  {
                    text: "E-kms Team",
                    icon: "",
                    link: "",
                    description: "(for any other KMS related request)"
                  },
            ]
            },
            {
              text: "How to ",
              icon: "",
              link: "/how-to",
            },
            ],
          },
        ];
      } else {
        this.menu = [
          // { text: "Home", icon: "fa fa-home", link: "" },
          {
            text: "Networks",
            icon: "fa fa-comments",
            link: "/network",
            children: [
              {
                text: "CoPs",
                icon: "regular fa fa-users",
                link: "/network/list/cop",
              },
              {
                text: "Business Units",
                icon: "regular fa fa-sitemap",
                link: "/network/list/bu",
              },
              {
                text: "Special Tags",
                icon: "regular fa fa-hashtag",
                link: "/network/list/strategictag",
              },
            ],
          },
          {
            text: "Knowledge",
            icon: "fa fa-puzzle-piece",
            link: "",
            children: [
              // {
              //   text: "Impacting Knowledges",
              //   icon: "fa fa-diamond",
              //   link: "/ik-archive"
              //   // query: {
              //   //   hideSources: true,
              //   //   source: "impactingknowledges",
              //   //   pageTitle: "Knowledge Nuggets Archive"
              //   // }
              // },
              {
                text: "Knowledge Nuggets",
                icon: "regular fa fa-gem",
                link: "/knowledge-nugget/archive",
              },
              {
                text: "IIM Challenges",
                icon: "regular fa fa-bullhorn",
                link: "",
                children: [
                  {
                    text: "Archive",
                    icon: "regular fa fa-search",
                    link: "/challenge/archive",
                  },
                  {
                    text: "Open",
                    icon: "regular fa fa-unlock-alt",
                    link: "",
                  },
                  {
                    text: "Closed",
                    icon: "regular fa fa-lock",
                    link: "",
                  },
                  {
                    text: "Popular",
                    icon: "regular fa fa-lightbulb",
                    link: "",
                  },
                ],
              },
              {
                text: "Ideas",
                icon: "regular fa fa-lightbulb",
                link: "/idea/archive",
                // query: {
                //   hideSources: true,
                //   source: "impactingknowledges",
                //   pageTitle: "Knowledge Nuggets Archive"
                // }
              },
              // {
              //   text: "Success stories",
              //   icon: "regular fa fa-book",
              //   link: "/success-story/archive"
              // },
              // {
              //   text: "Lesson Learned from LeLe",
              //   icon: "regular fa fa-gavel",
              //   link: `/dotlessonslearned`
              // }
            ],
          },
          {
            text: "Success stories",
            icon: "fa fa-book",
            link: "/success-story/archive",
          },
          {
            text: "Webinars ",
            icon: "fas fa-video",
            link: "",
            children: [
              {
                text: "Live soon",
                icon: "fa fa-broadcast-tower",
                link: "",
              },
              {
                text: "My Booking Pending",
                icon: "fa fa-folder",
                link: "",
              },
              {
                text: "Calendar & Booking",
                icon: "fas fa-calendar-alt",
                link: "/webinars/calendar",
              },
              {
                text: "Search",
                icon: "fas fa-video",
                link: "/webinars/list",
              },
            ],
          },
          {
            text: "Lesson Learned",
            icon: "lele-logo",
            link: "/lesson-learned/list",
          },
          {
            text: "Standards",
            icon: "Standards-logo",
            link: "",
            children: [
              {
                text: "Company Standards",
                icon: "Standards-logo",
                link: "https://rms.eni.com/RMS/Search/Display"
              }
            ]
          },
          // {
          //   text: "How to ",
          //   icon: "fa fa-question-circle",
          //   link: "/how-to"
          // },
          // {
          //   text: "Favorites ",
          //   icon: "fa fa-star",
          //   link: "/favorites"
          // }
          {
            text: "Help ",
            icon: "fa fa-question-circle",
            link: "",
            children: [
              {
                text: "Contacts",
                icon: "fa fa-address-book",
                link: "",
                children: [
                  {
                    text: "E-kms HelpDesk",
                    icon: "",
                    link: "",
                    description: "(for technical issues and access)"
                  },
                  {
                    text: "Eni Webinar",
                    icon: "",
                    link: "",
                    description: "(for problems with webinars, confirmation and test)"
                  },
                  {
                    text: "E-kms Team",
                    icon: "",
                    link: "",
                    description: "(for any other KMS related request)"
                  },
            ]
            },
            {
              text: "How to ",
              icon: "",
              link: "/how-to",
            },
            ],
          },
        ];
      }

      if (roles.admin || roles.kmTeam || this.userSvc.userCategory === UserCategory.Employee) {
        this.menu.splice(-1, 0, {
          text: "Technical Publications",
          icon: "fas fa-drafting-compass",
          link: "/tech-pub/look-for"
        })
      }

    }
  }
  getUlStyle(item: any): { [key: string]: string } {
    if (item.text === "Knowledge") {
      return { width: '230px' };
    }
    if (item.text === "Help ") {
      return { width: '170px' };
    }
    return { width: 'auto' };
  }


  ngAfterViewInit() {
    this.configSvc.assetsConfig
      .pipe(
        filter((key) => key != null),
        take(1)
      )
      .subscribe(() => {
        if ("Notification" in window && Notification.permission !== "granted") {
          this.askNotificationConfirm = true;

          setTimeout(() => {
            this.askNotificationConfirm = false;
            this.cd.markForCheck();
          }, 15000);
        }
      });
  }

  async listenToNotificationsChange() {
    if (this.notificationSvc.groupedNotifications == null) {
      this.isLoading = true;
      const refreshNotifications$ = this.notificationSvc.lastNotification$.pipe(
        // filter(n => !!n && n.Command === "notify")  TO DO
        tap(() => this.cd.markForCheck())
      );

      this.notificationSvc.notificationList$ = merge(
        // of([1]), DUPLICATE CALLS
        refreshNotifications$
      ).pipe(
        debounceTime(7000),
        switchMap(() => this.loadNotifications()),
        shareReplay(1),
        tap(() => (this.isLoading = false))
      );
    }
    // this.isLoading = true;
    // this.notificationSvc.notificationList$ =
    // await this.loadNotifications().toPromise();
    // this.isLoading = false;
  }
  get isFirstAccessPageActive() {
    return this.welcomeSvc.isFirstAccessActivated;
  }

  get totalNotificationsCount() {
    return this.notificationSvc.notifications$.pipe(map((m) => m.length));
  }
  // get totalUnreadNotificationsCount() {
  //   return this.notificationSvc.notifications$.pipe(
  //     map(m => m.filter(x => !x.read).length)
  //   );
  // }

  get showSearchBar() {
    return !/search/i.test(document.location.href);
  }
  findLink() {
    //   this.router.events
    //     .pipe(filter(ev => ev instanceof NavigationEnd))
    //     .subscribe((ev: NavigationEnd) => {
    //       console.log(this.menu);
    //       this.activeMenuItem = this.menu.find(item =>
    //           ev.url.includes(item.link);
    //       );
    //       this.cd.markForCheck();
    //     });
    // console.log(this.router.routerState.snapshot.url);
  }

  private highlightActivePage(url: string) {
    this.menu.forEach((r) => {
      if (r.link === url || this.isChildrenLoading(r, url)) {
        this.activeMenuItem = r;
      }
    });
  }

  private isChildrenLoading(element: MenuItem, url: string): boolean {
    return (
      element.children &&
      element.children
        .map((r) => r.link)
        .reduce((all, elem) => {
          return all || elem === url;
        }, false)
    );
  }

  readNotifications() {
    if (this.idList && this.idList.length > 0) {
      this.notificationSvc.readNotifications(this.idList).subscribe((el) => {
        this.idList = [];
        this.notificationSvc.notificationList$ = this.loadNotifications();
        this.cd.markForCheck();
      });
    }
  }
  showWidgetPage() {
    if (window.location.pathname === "/mobile-widgets") {
      this.router.navigateByUrl("/");
    } else {
      this.router.navigateByUrl(
        "/mobile-widgets"
        //   , {
        //     state: {
        //       menu: this.menu,
        //       kmsTeam: this.kmsTeam,
        //       kmsHelp: this.kmsHelp,
        //       kmsWebinar:this.kmsWebinar,
        //       liveSoon: this.liveSoon,
        //       myBookings: this.myBookings,
        //       expiringSoon: this.opened,
        //       expired: this.closed,
        //       mostVoted: this.popular
        //     }
        // }
      );
    }
  }
  goToNotifications() {
    this.resetNotificationsNumber();
    this.router.navigate(["/notifications"]);
  }

  resetNotificationsNumber() {
    this.notificationsNumber = 0;
    this.cd.markForCheck();
  }
  registerNotifications() {
    this.notificationSvc.registerNotifications();
    this.askNotificationConfirm = false;
  }

  groupNotifications(notifications: UserNotification[]) {
    this.notificationSvc.groupedNotifications = new Array<UserNotification>();
    const notificationsList = new Array<UserNotification>();
    const _this = this;
    const temp = _.groupBy(
      notifications.filter((el) => el.sourceObjectId != null),
      "sourceObjectId"
    );

    _.forEach(temp, (el, i) => {
      const groupedNotifications: UserNotification[] = [];
      el.forEach((r) => {
        const index = groupedNotifications
          .map((s) => s.sourceUserId)
          .indexOf(r.sourceUserId);
        if (index >= 0) {
          if (groupedNotifications[index].created < r.created) {
            groupedNotifications.splice(index, 1, r);
          }
        } else {
          groupedNotifications.push(r);
        }
      });
      temp[i] = groupedNotifications;
    });

    _.forEach(temp, function (el) {
      if (el.length > 1) {
        const tempEl = _.groupBy(el, "message");
        _.forEach(tempEl, function (tmpEl) {
          const notification = _this.createNotification(tmpEl);
          notificationsList.push(new UserNotification(notification));
        });
      } else {
        const notification = _this.createNotification(el);
        notificationsList.push(new UserNotification(notification));
      }
    });
    const nullSourceObjIdNot = notifications.filter(
      (el) => el.sourceObjectId === null
    );
    this.notificationSvc.groupedNotifications = [
      ...notificationsList,
      ...nullSourceObjIdNot,
    ];
    this.notificationSvc.groupedNotifications = _.orderBy(
      this.notificationSvc.groupedNotifications,
      "created",
      "desc"
    );
  }

  createNotification(data: UserNotification[]) {
    let notification;
    if (data && data.length > 0) {
      data = _.orderBy(data, "created", "desc");
      notification = data[0];
      notification.counter = data.length;
      if (
        data.length > 1 &&
        notification.sourceObjectType !== EntityType.User
      ) {
        notification.sourceUser.displayName = data.length + " people";
        data.map((item) => {
          notification.isRead = data.find((n) => n.isRead === false)
            ? false
            : true;
        });
      } else {
        notification.sourceUser.displayName = data[0].sourceUser.displayName;
      }
    }
    return notification;
  }

  loadNotifications() {
    return this.notificationSvc.getNotifications().pipe(
      tap((notifications) => {
        const notificationsToRead = notifications.filter(
          (n) => n.isRead === false
        );
        this.idList = notificationsToRead.map((n) => n.id);
        this.allNotifications = notifications;
        this.groupNotifications(this.allNotifications);
        this.notificationsNumber =
          this.notificationSvc.groupedNotifications &&
            this.notificationSvc.groupedNotifications.filter(
              (el) => el.isRead === false
            )
            ? this.notificationSvc.groupedNotifications.filter(
              (el) => el.isRead === false
            ).length
            : 0;
        this.cd.markForCheck();
      })
    );
  }

  getUnread() {
    if (this.userSvc.userId) {
      this.notificationSvc.getUnreadNotification().subscribe((val) => {
        this.notificationsNumber = val;
        this.cd.markForCheck();
      });
    }
  }

  // listenToUnreadCountChange() {
  //   this.notificationSvc.lastNotification$.subscribe(() => {
  //     this.getUnread();
  //   });
  // }

  getWebinarsLiveSoon() {
    this.liveSoon = [];
    this.webinarSvc.getWebinarsLiveSoon().then((response) => {
      response[0].results.forEach((r) => {
        this.liveSoon.push(r.document);
      });
      response[1].results.forEach((r) => {
        this.liveSoon.push(r.document);
      });
      this.liveSoon.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      //  this.myBookings.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      this.loading = false;
      this.cd.markForCheck();
    });
  }

  getBookingsMyBookings() {
    this.myBookings = [];
    this.webinarSvc.getMyBookings().then((response) => {
      response.results.forEach((r) => {
        this.myBookings.push(r.document);
      });
      this.loading = false;
      this.cd.markForCheck();
    });
  }

  get getMyBookings() {
    return this.myBookings.slice(0, 5);
  }

  get getLiveSoon() {
    return this.liveSoon.slice(0, 5);
  }

  private getExpiringSoon() {
    this.loadingIndicator = true;
    this.expiringSoon = this.searchSvc
      .queryRaw("ImpactingKnowledges", "", this.expiringSoonFilter)
      .pipe(
        map((challenges: KMSSearchResults<any>) =>
          challenges.results.map((r) => r.document)
        ),
        map((challenges) =>
          challenges.map((challenge) => {
            challenge.challengeEndDate = Utilities.adjustDateMilanToUtc(
              challenge.challengeEndDate
            );
            return challenge;
          })
        ),
        tap(() => (this.loadingIndicator = false)),
        catchError(() => {
          this.loadingIndicator = false;
          this.expiringSoonError = true;
          return of([]);
        })
      );
    this.expiringSoon.subscribe((x) => (this.opened = x));
  }

  private getExpired() {
    this.loadingIndicator = true;
    this.expired = this.searchSvc
      .queryRaw("ImpactingKnowledges", "", this.expiredFilter)
      .pipe(
        map((challenges: KMSSearchResults<any>) =>
          challenges.results.map((r) => r.document)
        ),
        map((challenges) =>
          challenges.map((challenge) => {
            challenge.challengeEndDate = Utilities.adjustDateMilanToUtc(
              challenge.challengeEndDate
            );
            return challenge;
          })
        ),
        tap(() => (this.loadingIndicator = false)),
        catchError(() => {
          this.loadingIndicator = false;
          this.expiredError = false;
          return of([]);
        })
      );
    this.expired.subscribe((x) => (this.closed = x));
  }

  private getMostVoted() {
    this.loadingIndicator = true;
    this.mostVoted = this.searchSvc
      .queryRaw("ImpactingKnowledges", "", this.filter)
      .pipe(
        map((challenges: KMSSearchResults<any>) =>
          challenges.results.map((r) => r.document)
        ),
        map((challenges) =>
          challenges.map((challenge) => {
            challenge.challengeEndDate = Utilities.adjustDateMilanToUtc(
              challenge.challengeEndDate
            );
            return challenge;
          })
        ),
        tap(() => (this.loadingIndicator = false)),
        catchError(() => {
          this.mostVotedError = true;
          return of([]);
        })
      );
    this.mostVoted.subscribe((x) => (this.popular = x));
  }

  private updateSearchFilters() {
    const todayDate = dayjs().format("YYYY-MM-DDTHH:mm:ss") + "Z";
    this.expiredFilter.filter += ` and challengeEndDate le ${todayDate}`;
    this.expiringSoonFilter.filter += ` and challengeStartDate le ${todayDate} and challengeEndDate ge ${todayDate}`;
    this.filter.filter += ` and challengeStartDate le ${todayDate}`;
  }

  navigate(link) {
    this.router.navigateByUrl(link);
  }

  openNewTab(link) {
    window.open(link, '_blank')
  }

  openBottomSheet(item) {
    this._bottomSheet.open(BottomSheetComponent, {
      data: item,
    });
  }
}

interface MenuItem {
  children?: MenuItem[];
  icon: string;
  text: string;
  link?: string;
  description?: string;
  query?: { [key: string]: any };
}
